import React from 'react'
import { Link } from 'gatsby'
//import aboutImage from '../../assets/images/about/about-img10.png'
import starIcon from '../../assets/images/star-icon.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
import nosCapteurs from '../../assets/images/nos-capteurs.png'

 
const ModCapteurs = () => {
    return (
        <section className="about-area ptb-70 bg-capteurs">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={nosCapteurs} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    Qualité de l’air
                                </span>
                                <h2>Améliorer la qualité de l’air  intérieur : Capteur CO2 connecté</h2>
                                <h3>Pourquoi s’équiper d’un capteur CO2 ?</h3>
                                <p>Le capteur permet de <span className='bold'>surveiller la qualité de l’air intérieur</span>, classe, bureau, cantine, salle de réunion, etc. et d’en informer les utilisateurs.</p>
                                Dans une salle équipée d’une VMC (ventilation mécanique contrôlée) le capteur permet de <span className='bold'>vérifier que le renouvellement de l’air</span> est efficient. En l’absence de VMC, le capteur indique à quel moment il faut aérer la pièce en ouvrant fenêtres et portes.
                                <p>En intérieur, une <span className='bold'>concentration supérieure à 800 ppm est le signe d’une aération insuffisante</span>.</p> 
                                <p>Cela à des répercutions sur notre organisme :  <span className='bold'>diminution de la concentration, des performances et du bien-être</span>.</p>

                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-right"></i>Demander un conseil<span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default ModCapteurs;