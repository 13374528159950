import React from 'react'
import product1 from '../../assets/images/services/product1.png'
import product2 from '../../assets/images/services/product2.png'
import product3 from '../../assets/images/services/product3.png'
import starIcon from '../../assets/images/star-icon.png'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'

const ContentThree = () => {
    return (
        <section className="overview-area ptb-70">
            <div className="container">
                {/* Right Image Style */}
                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content right-content">
                            <div>
                                <div className="about-content">
                                    <div className="content">
                                        <span className="sub-title">
                                            <img src={starIcon} alt="banner" /> 
                                            Capteur Pro
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <h2>Le capteur sans fil</h2>
                            <p>Un capteur de la qualité de l’air innovant, sur batterie, qui mesure le <span className='bold'>niveau de CO2, la température, l’humidité relative et la pression atmosphérique</span> dans tout environnement intérieur.<br></br>
                            Suivez vos données sur l’<span className='bold'>application</span> gratuite en connexion bluetooth avec une <span className='bold'>visualisation et un téléchargement des données sur 7 jours</span>.</p>
                            <div>
                                <div className="about-content">
                                    <div className="content">
                                        <span className="sub-title">
                                            <img src={starIcon} alt="banner" /> 
                                            Les caractéristiques
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <ul className="features-list">
                                <li><span><i className='bx bx-check'></i> 3 voyants LED pour visualiser le niveau de CO2</span></li>
                                <li><span><i className='bx bx-check'></i> Avertissements visuels et sonores</span></li>
                                <li><span><i className='bx bx-check'></i> Connecté pour recevoir les informations à distance</span></li>
                                <li><span><i className='bx bx-check'></i> Technologie NDIR (capteur infrarouge non dispersif)</span></li>
                                <li><span><i className='bx bx-check'></i> Ecran e-ink économe en énergie</span></li>
                                <li><span><i className='bx bx-check'></i> Classe de protection IP20</span></li>
                                <li><span><i className='bx bx-check'></i> Dimensions : 70x70x24 mm</span></li>
                                <li><span><i className='bx bx-check'></i> Marquage CE</span></li>
                                <li><span><i className='bx bx-check'></i> Fonctionne sur batterie autonomie de 4 ans</span></li>
                                <li><span><i className='bx bx-check'></i> Sans fil, pas de branchement électrique</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={product2} alt="features" />
                        </div>
                    </div>
                </div>
                {/* End Right Image Style */}

                {/* Left Image Style */}
                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            <img src={product1} alt="features" />
                        </div>
                    </div>
                    
                    <div className="overview-content">
                        <div className="content right-content">
                            <div>
                                <div className="about-content">
                                    <div className="content">
                                        <span className="sub-title">
                                            <img src={starIcon} alt="banner" /> 
                                            Base radio
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <h2>La base radio</h2>
                            <p>Les capteurs sont connectés sans fil à la station de base par voie radio jusqu’à 3 km de portée.<br></br>
                            L’interface permet de <span className='bold'>visualiser, d’analyser et de comparer les données</span> en temps réel, de <span className='bold'>définir des seuils d’alerte</span> et <span className='bold'>d’exporter des rapports</span>. Les données sont accessibles sur smartphone, tablette et ordinateur portable.</p>
                            <div>
                                <div className="about-content">
                                    <div className="content">
                                        <span className="sub-title">
                                            <img src={starIcon} alt="banner" /> 
                                            Les caractéristiques
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <ul className="features-list">
                                <li><span><i className='bx bx-check'></i> Mémoire interne : archivage de 10 ans</span></li>
                                <li><span><i className='bx bx-check'></i> Connexion Ethernet ou Wifi</span></li>
                                <li><span><i className='bx bx-check'></i> Disponible en mode déconnecté</span></li>
                                <li><span><i className='bx bx-check'></i> Serveur applicatif intégré (pas d’abonnement supplémentaire)</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* End Left Image Style */}

                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content right-content">
                            <div>
                                <div className="about-content">
                                    <div className="content">
                                        <span className="sub-title">
                                            <img src={starIcon} alt="banner" /> 
                                            Cloud
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <h2>Application Cloud</h2>
                            <p>Les données des capteurs sont <span className='bold'>centralisées sur une application</span> fournie lors de l’achat des capteurs. Elle permet de récupérer et exploiter les données de vos capteurs. Vous pouvez consulter vos informations sur votre smartphone, tablette ou ordinateur pour <span className='bold'>une supervision à distance</span>.</p>
                            <div>
                                <div className="about-content">
                                    <div className="content">
                                        <span className="sub-title">
                                            <img src={starIcon} alt="banner" /> 
                                            Les caractéristiques
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <ul className="features-list">
                                <li><span><i className='bx bx-check'></i> Tableau de bord de suivi en temps réel</span></li>
                                <li><span><i className='bx bx-check'></i> Collecte et analyse automatiques des données</span></li>
                                <li><span><i className='bx bx-check'></i> Notification de dépassement des seuils autorisés</span></li>
                                <li><span><i className='bx bx-check'></i> Sauvegarde automatique des données</span></li>
                                <li><span><i className='bx bx-check'></i> Intégration transparente avec vos systèmes existants</span></li>
                                <li><span><i className='bx bx-check'></i> Accessible de n’importe où à tout moment</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={product3} alt="features" />
                        </div>
                    </div>
                </div>
                {/* End Right Image Style */}

            </div>

            <div className="container pt-50">
                <ReactWOW delay='.1s' animation='fadeInRight'>
                    <div className="btn-box section-title h2-top">
                        <Link to="https://shop.wispeek.com/11-equipement" target="_blank" className="default-btn">
                            <i className="flaticon-right"></i> 
                            Boutique en ligne <span></span>
                        </Link>
                    </div>
                </ReactWOW>

            </div>

        </section>
    )
}

export default ContentThree