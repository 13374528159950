import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import UnProjet from "../components/Common/UnProjet"
import ModCapteurs from "../components/Capteurs/ModCapteurs"
import Content from "../components/Capteurs/Content"
import ContentTwo from "../components/Capteurs/ContentTwo"
import ContentThree from "../components/Capteurs/ContentThree"
import { Helmet } from "react-helmet"

const Capteurs = () => {
  return (
    <Layout>
      <Helmet>
        <title>Seekreet | Capteurs connectés</title>
        <meta name="description" content="Avoir des données c’est bien, les exploiter selon vos besoins c’est mieux ! Gamme de capteurs et solution de gestion des plans d’action, KPI, analyse et statistique. Certification de la données pour une traçabilité optimum..." />
      </Helmet>
      <Navbar />
      <PageBanner
          pageTitle="Capteurs" 
          homePageText="Accueil" 
          homePageUrl="/" 
          activePageText="Capteurs" 
      />
      <ModCapteurs />
      <Content />
      <ContentTwo />
      <ContentThree />
      <UnProjet />
      <Footer />
    </Layout>
  )
}

export default Capteurs