import React from 'react'
import {Link} from 'gatsby'
import projectStart from '../../assets/images/project-start1.png'
import shape from '../../assets/images/shape/circle-shape1.png'

const UnProjet = () => {
    return (
        <div className="project-start-area pt-70 pb-100 bg-grey">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-image">
                            <img src={projectStart} alt="project" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2>Vous avez un projet ?</h2>
                            <p>Nous vous proposons un appel (visio ou téléphonique) de 30 minutes pour échanger sur votre projet et vos besoins. Gratuit et sans engagement, profitez-en !</p>
                            
                            <Link to="https://share.seekreet.com/index.php/apps/appointments/pub/T3Kl1pnmmsY6tw%3D%3D/form" target="_blank" className="default-btn">
                                <i className="flaticon-web"></i> 
                                Prendre rendez-vous 
                                <span></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape} alt="project" />
            </div>
        </div>
    )
}

export default UnProjet;