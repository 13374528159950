import React from 'react'
// import icon1 from '../../assets/images/funfacts/fun-icon1.png'
// import icon2 from '../../assets/images/funfacts/fun-icon2.png'
// import icon3 from '../../assets/images/funfacts/fun-icon3.png'
// import icon4 from '../../assets/images/funfacts/fun-icon4.png'
// import icon5 from '../../assets/images/funfacts/fun-icon5.png'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import piece from '../../assets/images/icones/piece.gif'
import entreprise from '../../assets/images/icones/entreprise.gif'
import multiSite from '../../assets/images/icones/multi-site.gif'

const ContentTwo = () => {
    return (
        <div className="funfacts-area ptb-50">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    Pour quel type d’utilisation :
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row h2-top">
                    <div className="col-lg-4 col-sm-12 col-md-6">
                        <div className="single-funfacts-box">
                            <div className="icon">
                                <img src={piece} width='50%' alt="banner" />
                            </div>
                            <h3>Une pièce</h3>
                            <p>Capteur Pro + base radio</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-12 col-md-6">
                        <div className="single-funfacts-box">
                            <div className="icon">
                                <img src={entreprise} width='50%' alt="banner" />
                            </div>
                            <h3>Une entreprise/école</h3>
                            <p>Capteur Pro + base radio</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-12 col-md-6">
                        <div className="single-funfacts-box">
                            <div className="icon">
                                <img src={multiSite} width='50%' alt="banner" />
                            </div>
                            <h3>Multi-sites</h3>
                            <p>Capteur pro + base radio + Cloud</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <ReactWOW delay='.1s' animation='fadeInRight'>
                    <div className="btn-box section-title h2-top">
                        <Link to="https://shop.wispeek.com/11-equipement" target="_blank" className="default-btn">
                            <i className="flaticon-right"></i> 
                            Découvrir nos équipements <span></span>
                        </Link>
                    </div>
                </ReactWOW>

            </div>

        </div>
    )
}

export default ContentTwo