import React from 'react'
import serviceShape4 from '../../assets/images/services/service-shape4.png'
// import piece from '../../assets/images/icones/piece.gif'
// import entreprise from '../../assets/images/icones/entreprise.gif'
// import multiSite from '../../assets/images/icones/multi-site.gif'

const Content = () => {
    return (
        <section className="services-area ptb-70">
            <div className="container">
                <div className="section-title">
                    <h2>Une solution de surveillance complète</h2>
                </div>

                <div className="row" id="capteurs">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <h3>
                            Capteurs sans fil mobile
                            </h3>
                            <p>qui surveillent en continu vos indicateurs de mesure</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <h3>
                            Base radio
                            </h3>
                            <p>qui collecte et stocke les données issues des capteurs</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item ">
                            <h3>
                            Application Cloud
                            </h3>
                            <p>qui vous permet de consulter vos données à distance</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div> 
                </div>
                <h3 className="ptb-50 center">Une prise en main simple, rapide et intuitive des équipements.<br></br>Le capteur est fourni avec un kit de fixation murale avec vis antivol.</h3>
            </div>
        </section>
    )
}

export default Content